.actions-layout {

    .actions-wrapper {

        .col-left {
            width: 400px;
        }

    }

    @media(max-width: 768px) {

        .actions-wrapper {

            .col-left {
                width: 100%;
            }

            .col-right {
                display: none;
            }

        }

    }

}
