
@if ($theme == dark) {
    .text-dark {
        color: $white !important;
    }

    .bg-white.text-dark, .bg-white .text-dark {
        color: $black !important;
    }

}


.text-gradient-success {
    color: $green;
    background: linear-gradient(to right, darken($green, 20%), $green);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-info {
    color: $green;
    background: linear-gradient(to right, darken($teal, 20%), $teal);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
    color: $green;
    background: linear-gradient(to right, darken($orange, 20%), $orange);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-gradient-danger {
    color: $green;
    background: linear-gradient(to right, darken($red, 20%), $red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-info-original {
    color: $tealOriginal !important;
}

.overflow-text {
    margin-right: -150px;
}

.text-right .overflow-text {
    margin-left: -150px;
    margin-right: inherit
}

// p a {
//     color: $warning;
//     font-weight: bold;
// }

h1,h2,h3,h4,h5,h6 {
    // font-family: 'Roboto', sans-serif !important;
font-family: 'Open Sans', sans-serif;
// font-family: 'Lato', sans-serif;
// font-family: 'Montserrat', sans-serif;
// font-family: 'Oswald', sans-serif;
// font-family: 'Merriweather', serif;
// font-family: 'Roboto Slab', serif;
// font-family: 'Rubik', sans-serif;
// font-family: 'Libre Baskerville', serif;
// font-family: 'Arimo', sans-serif;
// font-family: 'Heebo', sans-serif;
// font-family: 'Raleway', sans-serif;
}