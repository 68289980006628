
.case-steps-container {
    // overflow-x: auto;
    padding: $spacer * 2 0;
}

.case-steps {

    position: relative;

    // overflow-x: auto;

    hr {
        // border-bottom: solid 4px $green;
        border: none;
        height: 4px;
        // background: linear-gradient(to right, $red 20%, $orange 30%, $green 60%) !important;;;
        background: $green;
    }

    span {

        &.step {
            font-size: $font-size-base * .9;
            position: absolute;
            left: 0;
            top: -14px;
            border-radius: 100%;
            background: $green;
            background: linear-gradient(87deg, $green 0, #80cf4e 100%) !important;;
            width: 30px;
            height: 30px;
            display: inline-block;
            text-align: center;
            padding-top: 5px;
            font-weight: bold;
            color: $white;
            border: solid 1px #aaa;
            cursor: pointer;

            &.active {
                background: $blue !important;
                background: linear-gradient(to bottom right, #0e93d4, #09618c) !important;

            }
            &:hover .prompt {

                display: block;
                width: 70px;

            }

        }

        &.prompt {
            display: none;
            position: absolute;
            bottom: 40px;
            left: -22.5px;
            background: $orange;
            font-size: 0.6rem;
            padding: $spacer * 0.8;
            border-radius: $border-radius;
        }
    }

    $spacing: 50px;
    @for $i from 0 through 30 {
        span.step:nth-child(#{$i}) {

            // -2 is to correct for a weird offset that is happing
            left: #{$spacing * ($i - 1)};
        }
    }

}
