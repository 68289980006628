.footer-wrapper {

    $footer-padding: 20px;

    // background: white;
    // border-top: darken($white, 20%);
    // box-shadow: $box-shadow-lg;;

    footer {
        padding-bottom: $footer-padding;
        padding-top: $footer-padding;
        // background-color: white;
        background-color: transparent;
       
    }

}

@media(min-height: 850px) {
    .footer-wrapper {

    
        // position: fixed;
        // bottom: 0;
        // left: $navbar-vertical-open-width;
        // right: 0;
    
        // footer {
           
        // }
    
    }
    
}

@media(max-width: 992px) {
    .footer-wrapper {
        position: static;
    }
}