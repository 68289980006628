.object-search-input {

    position: relative;

    .results {
        list-style: none;
        padding-left: 0;
        background: white;;
        box-shadow: $box-shadow;
        border: solid 2px $gray-200;

        position: absolute;
        z-index: 999999;
        left: 0;
        right: 0;
        bottom: -102px;

        max-height: 200px;
        overflow: auto;

        li {
            background: $body-bg;
            border-bottom: solid 1px white;;
            padding: $spacer;
            
            &:hover {
                background: $gray-200;;
                cursor: pointer;
            }

            &.title {
                background: white;;
            }

        }


    }

}

.modal {

    .object-search-input {

        .results {
            position: static ;
        }

    }

}