// //from https://loading.io/css/

// .lds-spinner {
//     color: official;
//     display: inline-block;
//     position: relative;
//     width: 80px;
//     height: 80px;
// }
// .lds-spinner div {
//     transform-origin: 20px 20px;
//     animation: lds-spinner 1.2s linear infinite;
// }
// .lds-spinner div:after {
//     content: " ";
//     display: block;
//     position: absolute;
//     top: 3px;
//     left: 37px;
//     width: 3px;
//     height: 9px;
//     border-radius: 20%;
//     background: #ddd;
// }
// .lds-spinner div:nth-child(1) {
//     transform: rotate(0deg);
//     animation-delay: -1.1s;
// }
// .lds-spinner div:nth-child(2) {
//     transform: rotate(30deg);
//     animation-delay: -1s;
// }
// .lds-spinner div:nth-child(3) {
//     transform: rotate(60deg);
//     animation-delay: -0.9s;
// }
// .lds-spinner div:nth-child(4) {
//     transform: rotate(90deg);
//     animation-delay: -0.8s;
// }
// .lds-spinner div:nth-child(5) {
//     transform: rotate(120deg);
//     animation-delay: -0.7s;
// }
// .lds-spinner div:nth-child(6) {
//     transform: rotate(150deg);
//     animation-delay: -0.6s;
// }
// .lds-spinner div:nth-child(7) {
//     transform: rotate(180deg);
//     animation-delay: -0.5s;
// }
// .lds-spinner div:nth-child(8) {
//     transform: rotate(210deg);
//     animation-delay: -0.4s;
// }
// .lds-spinner div:nth-child(9) {
//     transform: rotate(240deg);
//     animation-delay: -0.3s;
// }
// .lds-spinner div:nth-child(10) {
//     transform: rotate(270deg);
//     animation-delay: -0.2s;
// }
// .lds-spinner div:nth-child(11) {
//     transform: rotate(300deg);
//     animation-delay: -0.1s;
// }
// .lds-spinner div:nth-child(12) {
//     transform: rotate(330deg);
//     animation-delay: 0s;
// }
// @keyframes lds-spinner {
//     0% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0;
//     }
// }



.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid #ddd;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ddd transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  