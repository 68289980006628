.signature-wrapper {
    
    // width: 845px;
    // max-width: 95%;
    // max-width: 1000;
    margin: auto;

    .card {
        border-radius: 0 !important;
    }

    .initialCanvas {
        background-color: white;
    }

    .signature-box {
        border: solid 1px $gray-200;
    }

    .iframe {
        border: solid 2px $black;
        border-bottom: solid 4px $black;
        overflow: auto; 
        -webkit-overflow-scrolling: touch;
    }

} 