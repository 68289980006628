
.g-sidenav-pinned .sidenav .navbar-heading {
    color: $white !important;
}

.navbar {

    padding: 10px 0;

    .navbar-brand-img {
        width: 150px;
    }

    &.navbar-vertical {

        hr {
            border-top-color: darken($white, 90%) !important;
            padding-left: 0;
            padding-right: 0;
        }

        .sidenav-toggler-line {
            background-color: white;
        }

        i {
            color: $white;
        }

        .active {

            .nav-link {
                left: 10px;
            }

        }

        .nav-link {
            transition: all 0.3s;
            left: 0;
            position: relative;
            font-weight: normal !important;
            width: calc(100% - 10px);
            &:hover {

                left: 10px;

            }

        }

    }

    &.navbar-top {

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 100;

        .show > .nav-link {
            color: lighten($body-color, 15%) !important;

        }

        .nav-link {
            &:hover {
                color: lighten($body-color, 15%) !important;
                cursor: pointer;
            }

            color: $body-color;

        }
        .dropdown-item:hover {
            background-color: darken($blue, 4%);
            color: $white;
            cursor: pointer;
        }

    }

}



.nav-pills {

    .nav-link.active,
    .show > .nav-link a {
        color: darken($white, 10%) !important;
        background: $primary;
        background: linear-gradient(to bottom right, lighten($primary, 25%), lighten($primary, 10%));
        border-color: lighten($primary, 15%);
    }
}

.nav-fill {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
}
.navbar.navbar-top .dropdown-item:hover {
    color: inherit;
}

.navbar-vertical .navbar-brand > img,
.navbar-vertical .navbar-brand-img {
    max-width: 130%;
    position: relative;
    top: -1px;
}
@media(max-width: 1000px) {
    .navbar-vertical .navbar-brand > img,
    .navbar-vertical .navbar-brand-img {
        max-width: 100%;
        position: relative;
        top: -1px;
    }
}

.nav-master-menu {

    min-width: 600px !important;
    padding: $card-spacer-y $card-spacer-x;

    &.calendar-menu {
        min-width: 350px !important;
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    h2 {
        border-bottom: solid 1px $gray-200;
    }

}

.under-nav {

    background: lighten($blue, 8%) !important;

    position: fixed;
    top: 49px;
    left: 0;
    right: 0;
    z-index: 50;
    border-top: solid 1px $white;

    .navbar {
        padding: 0;
    }

    .nav-link {
        display: inline-block;
        padding: $spacer * 1.3 $spacer * 1.8;
        height: 100%;
        border-left: solid 1px rgba(255,255,255,.2);
        font-weight: bold;
        &:hover {
            background: lighten($blue, 30%);
        }
        &.active {
            background: lighten($blue, 15%);
        }
        &:last-child {
            border-right: solid 1px rgba(255,255,255,.2);
        }

    }
    @media(max-width: 870px) {
        .nav-link {
            font-size: $font-size-base * 0.9;
        }
    }

    @media(max-width: 820px) {
        .nav-link {
            font-size: $font-size-base * 0.8;
        }
    }



}

.navbar-top.border-bottom.navbar {
    border-color: black !important;
}

.navbar-mobile {

    background: $primary;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

}

.navbar-mobile-content {

    background: lighten($primary, 5%);
    border-top: solid 1px white;
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    overflow-y: auto;

    ul {
        padding-left: 0;

        li {
            list-style: none;

            &.active {
                background: darken($primary, 0%);
            }
            &.divider {
                background: linear-gradient(to right, darken($primary, 5%), darken($primary, 12%));
                border-top: solid 1px rgba(255,255,255,.5);
                border-bottom: solid 1px rgba(255,255,255,.5);
            }

            .badge {
                background: $green;
                float: right;
                color: white;
                display: inline-block;
                width: 50px;
                font-weight: bold;
                font-size: .7rem !important;
            }

            a {
                color: white;
                display: block;
                padding: $spacer * 1.5 $spacer * 2;


                i {
                    display: inline-block;
                    width: 30px;
                }

            }

        }

    }

}
