
@keyframes shadow-pulse {
    0% {
         box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);

    }
    100% {

         box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }
}


.btn-pulse {
   animation: shadow-pulse 1s infinite;
}

@if ($theme == dark) {

     .btn.btn-neutral {
        background: lighten($black, 5%);
        border-color: lighten($black, 1%);
        color: $white;
     }

 }

.btn {

     &.btn-primary,&.btn-info {
          background: $teal;
          background: linear-gradient(to bottom right, lighten($teal, 25%), lighten($teal, 10%));
          border-color: lighten($teal, 15%);
     }

     &.btn-success {
          border-color: darken($green, 5%) !important;

     }

     &.btn-outline-primary {
          border: solid 1px $tealOriginal !important;
          color: $tealOriginal !important;
     }


}
