
@media(max-width: 768px) {
    .react-bootstrap-table-pagination-total {
         display: none;
    }

    .dataTables_filter {
        input, select {
            width: 100%;
        }
    }

    .dataTables_length {
        display: none !important
    }

}

.table {

    // table-layout: fixed;

    td {
        vertical-align: middle;
        white-space: normal;
        word-break: break-all;
    }

    .aligned-image {
        position: relative;
        margin-top: -0px;

    }

}




@if ($theme == dark) {
    .page-item.disabled .page-link {

        background: $gray-900;
        border-color: $blackOffset;
    }
}

.react-bs-table-csv-btn {
    background: $primary !important;
    border-color: $primary !important;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: $teal;
    border-color: $teal;
}

.table-no-margin {
    table.table {
        margin-bottom: 0;
    }
}

.table-responsive {

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-md-6:first-child, .col-xs-6:first-child, .col-sm-6:first-child, .col-lg-6:first-child {
        padding-left: 0;;
    }

    .col-md-6:last-child, .col-xs-6:last-child, .col-sm-6:last-child, .col-lg-6:last-child {
        padding-right: 0;;
    }

}

.table-not-fixed {

    table.table {
        table-layout: unset;

        td {
            word-break: break-word;
            white-space: pre;
        }
    }

}

table.table {

    td {
        word-break: break-word;
        // white-space: pre;
    }
}

.react-bootstrap-table-pagination .dataTables_length select.form-control-sm {
    width: 40px;
    text-align: center;
    margin: auto;
}


.table-parent-bordered {

    .table {

        td {
            border: solid 4px $gray-200;
        }

        .react-json-view {
            font-size: 1.1rem;
        }

    }

}
